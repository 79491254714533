
    import confetti from 'canvas-confetti';
    import { sleep } from '../utils/sleep';

    export default {
        props: {
            sleep: {
                type: Number,
                default: 0,
            },
        },

        emits: ['finished'],

        computed: {
            reduceMotion () {
                if (typeof window !== 'undefined' && window.matchMedia) {
                    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
                }
                // Default to false if matchMedia is not supported or window is undefined
                return false;
            },
        },

        mounted () {
            this.$nextTick(async () => {
                await sleep(this.sleep);
                if (!this.reduceMotion) {
                    this.fireConfetti();
                    await sleep(1000);
                    this.$emit('finished');
                }
                else {
                    // Handle case where reduced motion is preferred
                    this.$emit('finished');
                }
            });
        },

        methods: {
            fireConfetti () {
                const count = 100;
                const defaults = {
                    origin: { y: 0.6 },
                    colors: ['#235792', '#ffffff', '#5db3e2'],
                    scalar: 1.5,
                };

                function fire (particleRatio, opts) {
                    confetti({
                        ...defaults,
                        ...opts,
                        particalCount: Math.floor(count * particleRatio),
                    });
                }

                fire(0.25, {
                    spread: 10,
                    startVelocity: 55,
                });
                fire(0.2, {
                    spread: 30,
                });
                fire(0.35, {
                    spread: 50,
                    decay: 0.91,
                    scalar: 0.8,
                });
                fire(0.1, {
                    spread: 60,
                    startVelocity: 25,
                    decay: 0.92,
                    scalar: 1.2,
                });
                fire(0.1, {
                    spread: 60,
                    startVelocity: 45,
                });
            },
        },
    };
