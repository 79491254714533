import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "modal inky-modal",
  role: "dialog",
  "aria-labelledby": "dialog4Title"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "modal-bottom" }
const _hoisted_10 = ["aria-label"]
const _hoisted_11 = { key: 0 }

import { ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';
    import SocialShare from '@components/SocialShare.vue';
    import ConfettiAnimation from '@components/ConfettiAnimation.vue';

    // Setup
    
export default {
  __name: 'InkyModal',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();
    const showContent = ref(false);

    const store = useStore();
    const profile = computed(() => store.state.profile);

    const { closeModal, isOpen, openModal } = useBaseModal('inky-confirmation', rootEl);
    const { vT, t } = useI18next(['aria', 'buttons', 'inky_modal']);
    const prize = ref('default');

    const prize_copy = computed(() => {
        const prizeWon = prize.value;
        return t(`${prizeWon}`);
    });

    const close = () => {
        closeModal();
    };

    const onFinished = () => {
        showContent.value = true;
    };

    const open = async (prizeWon) => {
        prize.value = prizeWon;
        await openModal();
    };

    // Compute reduceMotion based on prefers-reduced-motion media query
    const reduceMotion = computed(() => {
        if (typeof window !== 'undefined' && window.matchMedia) {
            return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        }
        return false; // Default to false if matchMedia is not supported or window is undefined
    });

    // Expose
    __expose({
        open,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _withModifiers(close, ["self"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_unref(isOpen) && !reduceMotion.value)
          ? (_openBlock(), _createBlock(ConfettiAnimation, {
              key: 0,
              sleep: 500,
              onFinished: onFinished
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "reduce-motion",
          style: _normalizeStyle({
                        opacity: reduceMotion.value ? (showContent.value ? 1 : 1) : showContent.value ? 1 : 0,
                        transition: reduceMotion.value ? 'none' : 'opacity 0.3s ease-in-out',
                    })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "button close",
              "aria-label": _unref(t)('aria:close_modal'),
              onClick: close
            }, [
              _createTextVNode(" X "),
              _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
                [_unref(vT), 'aria:close_modal']
              ])
            ], 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (prize_copy.value.image)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: `public/img/inky/${prize_copy.value.image}`,
                  alt: "",
                  onLoad: _cache[0] || (_cache[0] = (...args) => (_ctx.fireConfetti && _ctx.fireConfetti(...args)))
                }, null, 40, _hoisted_6))
              : _createCommentVNode("", true),
            _createElementVNode("h2", {
              id: "dialog4Title",
              innerHTML: _unref(t)('headline_copy')
            }, null, 8, _hoisted_7),
            _createElementVNode("h3", {
              innerHTML: _unref(t)('subheadline_copy', { first_name: profile.value.first_name, prize_copy: prize_copy.value.name })
            }, null, 8, _hoisted_8),
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'body_copy']
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "button",
              "aria-label": _unref(t)('aria.close_modal'),
              onClick: close
            }, [
              _withDirectives(_createElementVNode("span", null, null, 512), [
                [_unref(vT), 'buttons:go_back']
              ])
            ], 8, _hoisted_10)
          ]),
          _createVNode(SocialShare),
          (_unref(t)(`${prize.value}.disclaimer`))
            ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_11, null, 512)), [
                [_unref(vT), `${prize.value}.disclaimer`]
              ])
            : _createCommentVNode("", true)
        ], 4)
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}