import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-error pl-s pr-s" }
const _hoisted_2 = { class: "mt-s" }
const _hoisted_3 = ["aria-label"]

import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { useBasePage } from '@composables/page';

    // Setup
    
export default {
  __name: 'ErrorPage',
  props: {
        error: {
            type: String,
            default: () => 'generic',
        },
    },
  setup(__props) {

    useBasePage('error');
    const store = useStore();

    const { t, vT } = useI18next('error');

    // Setup
    const props = __props;

    const messagesMap = {
        country: 'generic_ineligible',
        email: 'ineligible_email',
        loyalty: 'generic_ineligible',
        partner: 'ineligible_partner',
    };

    const whichError = computed(() => {
        /*
            Available errors
            /error/country
            /error/email
        */
        const errorCopy = messagesMap[props.error] || 'generic';
        return errorCopy;
    });

    const handleCtaClick = () => {
        window.location.href = store.state.app.siteURL;
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
      [_unref(vT), `headline_copy.${whichError.value}`]
    ]),
    _createElementVNode("p", null, _toDisplayString(_unref(t)(`body_copy.${whichError.value}`, {
                tb2Url: _unref(store).state.app.tb2URL,
                target: '_blank',
            })), 1),
    (_ctx.$t(`cta.${whichError.value}`))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "button",
          "aria-label": _ctx.$t(`cta.${whichError.value}`),
          onClick: handleCtaClick
        }, _toDisplayString(_ctx.$t(`cta.${whichError.value}`)), 9, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
}

}