import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "dialog1Title",
  "aria-describedby": "dialog1Desc"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { id: "dialog1Desc" }
const _hoisted_9 = { class: "modal-bottom" }
const _hoisted_10 = ["aria-label"]

import { ref, computed, nextTick } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';

    // Setup
    
export default {
  __name: 'LoginModal',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();
    const h2 = ref();

    const store = useStore();
    const profile = computed(() => store.state.profile);

    const { closeModal, isOpen, openModal } = useBaseModal('login-confirmation', rootEl);
    const { vT, t } = useI18next(['modals', 'aria', 'buttons']);
    const version = ref('default');

    const close = () => {
        closeModal();
        store.dispatch('profile/removeDailyVisitCookie');
    };

    const open = async (modalVersion = 'default') => {
        version.value = modalVersion;
        const modalPromise = openModal();

        nextTick(() => h2.value.focus());

        await modalPromise;
    };

    // Expose
    __expose({
        open,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _withModifiers(close, ["self"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "button close",
            "aria-label": _unref(t)('aria:close_modal'),
            onClick: close
          }, [
            _createTextVNode(" X "),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:close_modal']
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: `public/img/login_${version.value}.jpg`,
            alt: `${version.value}`
          }, null, 8, _hoisted_6),
          _createElementVNode("h2", {
            id: "dialog1Title",
            ref_key: "h2",
            ref: h2,
            tabindex: "-1",
            innerHTML: _unref(t)('login_confirmation.headline_copy', { first_name: profile.value.first_name })
          }, null, 8, _hoisted_7),
          _withDirectives(_createElementVNode("p", _hoisted_8, null, 512), [
            [_unref(vT), `login_confirmation.body_copy.${version.value}`]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "button",
            "aria-label": _unref(t)('aria.close_modal'),
            onClick: close
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'buttons:continue']
            ])
          ], 8, _hoisted_10)
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}