import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "dialog3Title"
}
const _hoisted_2 = { class: "modal-top" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = ["alt"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "modal-bottom" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "disclaimer" }

import { ref } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useBaseModal } from '@composables/modal';

    // Setup
    
export default {
  __name: 'StoreNotificationModal',
  setup(__props, { expose: __expose }) {

    const rootEl = ref();

    const { closeModal, isOpen, openModal } = useBaseModal('login-confirmation', rootEl);
    const { vT, t } = useI18next(['modals', 'aria', 'buttons']);
    const version = ref('');

    const close = () => {
        closeModal();
    };

    const open = (modalVersion = '') => {
        if (modalVersion === '') return;
        version.value = modalVersion;
        openModal();
    };

    // Expose
    __expose({
        open,
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _withDirectives(_createElementVNode("section", {
      ref_key: "rootEl",
      ref: rootEl,
      class: "modal-overlay",
      onClick: _withModifiers(close, ["self"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "button close",
            "aria-label": _unref(t)('aria:close_modal'),
            onClick: close
          }, [
            _createTextVNode(" X "),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:close_modal']
            ])
          ], 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: "public/img/store_notification.jpg",
            alt: _unref(t)('aria:presents')
          }, null, 8, _hoisted_6),
          _createElementVNode("h2", {
            id: "dialog3Title",
            innerHTML: _unref(t)('store_reminder.headline_copy')
          }, null, 8, _hoisted_7),
          _withDirectives(_createElementVNode("p", null, null, 512), [
            [_unref(vT), `store_reminder.body_copy.${version.value}`]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "button",
            "aria-label": _unref(t)('aria.close_modal'),
            onClick: close
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'store_reminder.button_copy']
            ])
          ], 8, _hoisted_9)
        ]),
        _withDirectives(_createElementVNode("p", _hoisted_10, null, 512), [
          [_unref(vT), 'store_reminder.disclaimer']
        ])
      ])
    ], 512), [
      [_vShow, _unref(isOpen)]
    ])
  ]))
}
}

}