import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/close.png'


const _hoisted_1 = {
  class: "modal",
  role: "dialog",
  "aria-labelledby": "dialog7Title"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = { id: "dialog7Title" }
const _hoisted_5 = ["aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "close",
        "aria-label": _ctx.t('aria:close_modal'),
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: _ctx.t('aria:close_modal')
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_2),
      _createElementVNode("h2", _hoisted_4, _toDisplayString($props.headline), 1),
      _createElementVNode("p", null, _toDisplayString($props.body), 1),
      _createElementVNode("a", {
        href: "#",
        class: "button",
        "data-e2e": "error-modal-close",
        "aria-label": _ctx.t('aria:close_modal'),
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, "Close", 8, _hoisted_5)
    ])
  ]))
}