
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligiblePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligiblePage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegistrationPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegistrationPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            return '/calendar';
        }
        // Default routes for returning users (reg cookie) who are not logged-in.
        else if (store.getters['profile/returning']) {
            return '/login';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/login', component: LoginPage, meta: { public: true } },
    { path: '/register', component: RegistrationPage, meta: { public: true } },
    { path: '/ineligible/:type?', component: IneligiblePage, props: true, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
];
