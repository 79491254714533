import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-container" }
const _hoisted_2 = ["loop", "aria-label"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "visually-hidden" }
const _hoisted_5 = { class: "visually-hidden" }

import { onMounted, ref } from 'vue';

    import { useI18next } from '@composables/i18next';

    import PlayIcon from '@components/icons/PlayIcon.vue';
    import PauseIcon from '@components/icons/PauseIcon.vue';

    const videoUrl = 'https://eprize-content.s3.amazonaws.com/culvers/40yearsofdelicious/animations/';

    
export default {
  __name: 'VideoPlayer',
  props: {
        label: {
            type: String,
            default: '',
        },
        video: {
            type: String,
            required: true,
        },
        loop: {
            type: Boolean,
            default: false,
        },
    },
  emits: ['ended'],
  setup(__props, { emit: __emit }) {

    

    const emit = __emit;

    const { vT } = useI18next('aria');

    const videoEnded = () => {
        emit('ended');
    };

    const videoElement = ref(null);
    const isPlaying = ref(false);

    const togglePlayPause = () => {
        if (videoElement.value.paused) {
            videoElement.value.play();
            isPlaying.value = true;
        }
        else {
            videoElement.value.pause();
            isPlaying.value = false;
        }
    };

    onMounted(() => {
        videoElement.value.play();
        isPlaying.value = true;
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      ref_key: "videoElement",
      ref: videoElement,
      autoplay: "",
      crossorigin: "anonymous",
      disablepictureinpicture: "",
      disableremoteplayback: "",
      muted: "",
      playsinline: "",
      preload: "",
      loop: __props.loop,
      oncontextmenu: "return false",
      "aria-label": `${__props.label}`,
      onEnded: videoEnded
    }, [
      _createElementVNode("source", {
        src: `${videoUrl}${__props.video}.mp4`,
        type: "video/mp4"
      }, null, 8, _hoisted_3)
    ], 40, _hoisted_2),
    _createElementVNode("button", {
      class: "play-pause-button",
      onClick: togglePlayPause
    }, [
      (isPlaying.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(PauseIcon, { "aria-hidden": "true" }),
            _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
              [_unref(vT), 'aria:video_pause']
            ])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(PlayIcon, { "aria-hidden": "true" }),
            _withDirectives(_createElementVNode("span", _hoisted_5, null, 512), [
              [_unref(vT), 'aria:video_play']
            ])
          ], 64))
    ])
  ]))
}
}

}